<template>
  <b-card>
    <g-form @submit="save">
      <!-- <b-tabs content-class="mt-3" justified>
      <b-tab :title="$t('mainData')" active> -->
      <b-row>
        <b-col
          cols="12"
          class="d-flex justify-content-center mb-2"
        >
          <!-- media -->
          <b-media no-body>
            <b-media-aside>
              <b-link>
                <b-img
                  ref="previewEl"
                  rounded
                  height="80"
                  width="80"
                  :src="url"
                />
              </b-link>
              <!--/ avatar -->
            </b-media-aside>

            <b-media-body class="mt-75 ml-75">
              <!-- upload button -->
              <b-button
                variant="primary"
                size="sm"
                class="mb-75 mr-75"
                @click="$refs.imgupload.$el.click()"
              >
                {{ $t("change") }}
              </b-button>
              <b-form-file
                ref="imgupload"
                accept=".jpg, .png, .gif"
                :hidden="true"
                plain
                @change="onFileChanged"
              />
              <!--/ upload button -->
            </b-media-body>
          </b-media>
          <!--/ media -->
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <!-- englishName  -->
          <g-field
            id="arabicName"
            :value.sync="selectedItem.arabicName"
            label-text="arabicName"
            rules="required"
            name="arabicName"
          />
        </b-col>
        <b-col md="4">
          <!-- englishName  -->
          <g-field
            id="englishName"
            ref="englishName"
            :value.sync="selectedItem.englishName"
            label-text="englishName"
            name="englishName"
          />
        </b-col>
        <b-col md="4">
          <!-- adress  -->
          <g-field
            id="adress"
            :value.sync="selectedItem.address"
            label-text="adress"
            name="adress"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <!-- email  -->
          <g-field
            id="email"
            :value.sync="selectedItem.email"
            label-text="email"
            rules="email"
            name="email"
          />
        </b-col>
        <!-- phone 1  -->
        <b-col md="4">
          <label
            style="font-size: 14px; margin-bottom: 7px"
            for="phone"
          >
            {{ $t("phone") }} 1
          </label>
          <b-form-input
            v-model="selectedItem.phone1"
            type="number"
          />
        </b-col>
        <!-- phone 2  -->
        <b-col md="4">
          <label
            style="font-size: 14px; margin-bottom: 7px"
            for="phone"
          >
            {{ $t("phone") }} 2
          </label>
          <b-form-input
            v-model="selectedItem.phone2"
            type="number"
          />
        </b-col>
      </b-row>
      <b-row>
        <!-- fax  -->
        <b-col md="4">
          <g-field
            id="HomePhone"
            :value.sync="selectedItem.homePhone"
            label-text="HomePhone"
            name="HomePhone"
          />
        </b-col>
        <b-col md="4">
          <!-- fiscalYearStart  -->
          <!-- <g-picker
            :value.sync="selectedItem.fiscalYearStart"
            :disabled = "transactionCount > 0"
            label-text="academicYearStart"
          /> -->
          <label
            class="dateLabel"
            for="example-datepicker"
            style="font-size: 14px;"
          >{{
            $t("academicYearStart")
          }}</label>
          <b-form-datepicker
            id="example-datepicker"
            v-model="currentYear.startDate"
            disabled
            locale="ar"
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
            class="mb-2"
            name="date"
          />
        </b-col>
        <b-col md="4">
          <label
            class="dateLabel"
            for="example-datepicker"
            style="font-size: 14px;"
          >{{
            $t("academicYearEnd")
          }}</label>
          <b-form-datepicker
            id="example-datepicker"
            v-model="currentYear.endDate"
            disabled
            locale="ar"
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
            class="mb-2"
            name="date"
            @input="
              () => {
                $refs['items-table'].refreshTable();
              }
            "
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <g-field
            label-text="taxPercentage"
            rules="required|min_value:0|max_value:100"
            :value.sync="selectedItem.taxPercentage"
            type="number"
            name="value"
          />
        </b-col>
        <!-- taxNumber  -->
        <b-col md="4">
          <g-field
            id="taxNumber"
            rules="integer"
            :value.sync="selectedItem.taxRegistrationNumber"
            label-text="taxNumber"
            name="scalesCode"
            type="number"
          />
        </b-col>
        <!-- registerNumber  -->
        <b-col md="4">
          <g-field
            id="registerNumber"
            :value.sync="selectedItem.registrationNumber"
            label-text="registerNumber"
            name="registerNumber"
          />
        </b-col>

        <!-- countryCode -->
        <b-col md="4">
          <g-field
            id="countryCode"
            :value.sync="selectedItem.countryCode"
            label-text="countryCode"
            name="countryCode"
          />
        </b-col>
      </b-row>
      <!-- <label class="font-size:20px;">{{ $t("acceptanceAge") }}</label>
      <b-row>
        <b-col md="4">
          <g-field
            id="acceptanceAgeDays"
            rules="required|min_value:1|max_value:31|integer"
            :value.sync="selectedItem.acceptanceAgeDays"
            label-text="acceptanceAgeDay"
            type="number"
            name="acceptanceAgeDays"
          />
        </b-col>
        <b-col md="4">
          <g-field
            id="acceptanceAgeMonths"
            rules="required|min_value:1|max_value:12|integer"
            :value.sync="selectedItem.acceptanceAgeMonths"
            label-text="acceptanceAgeMonth"
            type="number"
            name="acceptanceAgeMonths"
          />
        </b-col>
        <b-col md="4">
          <g-field
            id="acceptanceAgeYears"
            rules="required|min_value:1|integer"
            :value.sync="selectedItem.acceptanceAgeYears"
            label-text="acceptanceAgeYear"
            type="number"
            name="acceptanceAgeYears"
          />
        </b-col>
      </b-row> -->
      <b-row>
        <!-- notes -->
        <b-col md="12">
          <b-form-group :label="$t('notes')">
            <b-form-textarea
              id="textarea"
              v-model="selectedItem.notes"
              label-text="Notes"
              rows="3"
              max-rows="6"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <!-- </b-tab> -->
      <b-row
        v-permission="'editSchool'"
      >
        <b-col cols="12">
          <hr>
        </b-col>
        <b-col
          cols="12"
          class="d-flex justify-content-end"
        >
          <b-button
            v-permission="$route.meta.permission"
            class="mx-1"
            type="submit"
            variant="primary"
            data-action-type="save"
          >
            {{ $t("save") }}
          </b-button>
        </b-col>
      </b-row>
      <!-- </b-tabs> -->
    </g-form>
  </b-card>
</template>

<script>
export default {
  data() {
    return {
      selectedItem: {
        englishName: '',
        acceptanceAgeDays: 0,
        acceptanceAgeMonths: 0,
        acceptanceAgeYears: 0,
        fiscalYearEnd: null,
      },
      url: '',
      filter: {
        fromDate: null,
        toDate: null
      }
    };
  },
  computed: {},
  watch: {
    'selectedItem.fiscalYearStart'(fiscalStart) {
      const startYear = new Date(fiscalStart).getFullYear();
      const startMonth = new Date(fiscalStart).getMonth();
      const startDay = new Date(fiscalStart).getDate();
      const endDate = new Date(startYear + 1, startMonth, startDay - 1);
      this.selectedItem.fiscalYearEnd = this.getDate(endDate);
    }
  },
  beforeMount() {
    this.filter.fromDate = this.getDate(this.currentYear.startDate);
    this.filter.toDate = this.getDate(this.currentYear.endDate);
  },
  mounted() {
    this.url = 'default_image.jpg';
    // const startDate = this.getFirstDate();
    this.selectedItem.fiscalYearStart = this.getFirstDate();
    this.getData();
  },
  methods: {
    getData() {
      this.get({ url: 'Schools/@current' }).then((data) => {
        this.selectedItem = data;
        this.url = this.selectedItem.logoUrl
          ? `${this.domain}${this.selectedItem.logoUrl}`
          : 'default_image.jpg';
        this.selectedItem.fiscalYearStart = this.getDate(data.fiscalYearStart);
        this.selectedItem.fiscalYearEnd = this.getDate(data.fiscalYearEnd);
      });
    },
    onFileChanged(event) {
      const file = event.target.files[0];
      this.selectedItem.imageExtension = file.name.split('.').pop();
      this.toBase64(file)
        .then((file1) => {
          this.selectedItem.base64logo = file1.split(',').pop();
        })
        .then(() => {
          this.logoModel = {
            extension: this.selectedItem.imageExtension,
            base64Content: this.selectedItem.base64logo,
          };

          this.create({
            url: 'Schools/@current/logo',
            data: this.logoModel,
          }).then((data) => {
            this.selectedItem.logoUrl = data;
          });
        });
      this.url = URL.createObjectURL(file);
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    save() {
      this.update({
        url: 'Schools',
        data: this.selectedItem,
        id: '@current',
      }).then(() => {
        this.doneAlert({ text: this.$t('updatedSuccessfully') });
        window.location.reload();
        this.getData();
      });
    },
  },
};
</script>
